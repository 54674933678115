.bl-herohome {
    position: relative;

    height:100vh;
    height:100lvh;

    .ctrimage {
        @include absposfull;
        z-index: -1;
        img {
            @include absposfull;
            object-fit: cover;
            object-position: center center;
        }
    }

    display:flex;
    justify-content: center;
    align-items: center;
    .ctrlogo {
        @include rem-calc-sl(width, 430, 0.5);
        img {
            @include w100bl;
        }
    }

    .ctrarrowdown {
        position: absolute;
        left:50%;
        @include rem-calc-sl(bottom, 40, 0.5);
        transform: translateX(-50%);

        .icon {
            height:rem-calc(21);
            cursor: pointer;
            color:$white;
        }
    }
}

//typo
.bl-herohome {
}
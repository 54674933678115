footer {
  @include rem-calc-sl(padding-top, 30, 0.5);
  @include rem-calc-sl(padding-bottom, 30, 0.5);

  @include breakpoint(large) {
    .ctrmain {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  @include breakpoint(medium down) {
    .ctrmain {
      display: flex;
      flex-direction: column;
      gap:2em;
    }
    .ctrdummy {
      display:none;
    }
  }

  .ctrtotop {
    text-align: center;
    .icon {
      cursor: pointer;
    }
  }

  .ctrlogo {
    @include rem-calc-sl(width, 130, 0.75);
    img {
      @include w100bl;
    }
  }

  .ctrsocial {
    .icon {
      @include rem-calc-sl(height, 42, 0.6);
    }
  }
}

//typo
footer {
  @include typo-footer;
}
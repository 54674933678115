header {
  position: fixed;
  z-index: 10;
  @include rem-calc-sl(--menuinset, 56, 0.5);
  right:var(--menuinset);
  top:var(--menuinset);
}

//typo
header {
  .ctrvmenu {
    text-align: right;
    color:$white;
    text-transform: lowercase;

    ul {
      @include reset-list;
    }
    li + li {
      margin-top:0.5em;
    }
  }

  @include typo-menu;
}
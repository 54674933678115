//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

@mixin typo-base {
  //Dynamic Font Size (mobile, desktop)
  @include font-size-pt(20*0.75, 20);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:math.div(24, 20);
}

body {
  font-family:'Mooli', sans-serif;
  @include typo-base;
  -moz-osx-font-smoothing: grayscale;
  color:$darkblue;
}
a {
  color:inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}

@mixin typo-h1 {
  @include font-size-pt(48*0.75, 48);
  font-weight: 400;
  line-height: math.div(57, 48);
}
@mixin typo-h2 {
  font:inherit;
  font-size:1.5em;
}
@mixin typo-h3 {
  font:inherit;
}
@mixin typo-h4 {
  font:inherit;
}

@mixin typo-zitat {
  @include typo-h1;
}

@mixin typo-footer {
  @include font-size-pt(16*0.75, 16);
  font-weight: 400;
  line-height: math.div(21, 16);
}

@mixin typo-menu {
  //default typo
}

/*
@mixin typo-lead {
  @include typo-base;
  @include font-size-pt(28*0.75, 28);
  font-weight: 700;
  line-height:math.div(38, 28);
}
*/

strong {
  font-weight: 700;
}

address {
  font:unset; //remove italic browser style
}

p {
  margin:0 0 1em 0;
}
p:last-child {
  margin-bottom:0;
}
p a {
  //text-decoration: underline;
  //text-decoration-thickness: 1px;
}

h1 {
  @include typo-h1;
  padding-bottom:0.4em;
  margin:0 0 0.5em 0;
  width:fit-content;
  border-bottom:2px solid currentColor;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
h2 {
  @include typo-h2;
  margin:0 0 1em 0;
}
h3 {
  @include typo-h3;
  margin:0 0 1em 0;
}
h4 {
  @include typo-h4;
  margin:0;
}

@mixin text-decoration-underline {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.1em;
}
.bl-imageblock {
    position: relative;

    img {
        @include w100bl;
    }

    &.layoutleft {
        @include breakpoint(large) {
            width:75%;
        }
    }

    .ctrtextoverlay {
        @include typo-h1;
        position: absolute;
        @include rem-calc-sl(top, 100, 0.25);
        @include rem-calc-sl(width, 325, 0.75);

        @include breakpoint(large) {
            @include rem-calc-sl(right, 165, 0.5);
        }
        @include breakpoint(medium down) {
            @include rem-calc-sl(left, 100, 0.25);
        }
    }
}
$black:#000;
$white:#fff;
$darkblue:#3A557C;
$lightblue: #53959D;

@mixin bgcolors {
    &.bgcolor-lightblue {
        background-color: $lightblue;
        color:$white;
    }
    &.bgcolor-darkblue {
        background-color: $darkblue;
        color:$white;
        .button {
            --bgcolor:#{$lightblue};
        }
    }
}

:root {
    @include rem-calc-sl(--lrminmargin, 50, 0.5);
    @include rem-calc-sl(--layoutgridgap, 20, 0.5);
}



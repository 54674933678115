.buttonbar {
  @include breakpoint(large) {
    display: flex;
    @include rem-calc-sl(gap, 60, 0.5);
    justify-content: center;
  }
  @include breakpoint(medium down) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1em;
  }
}

.button {
  display: block;
  width: fit-content;
  min-width: rem-calc(140);
  padding: 1.0em 1.5em;
  color: $white;
  --bgcolor:#{$darkblue};
  background-color: var(--bgcolor);
  
  $outlinewidth:0.25em;
  outline:$outlinewidth solid var(--bgcolor);
  margin:$outlinewidth;
  
  cursor: pointer;
}
button.button {
  //remove default <button> styling
  //border:none;
  outline: none;
}

.button {
  transition: outline-width .2s;

  &:hover {
    outline-width:0;
  }
}

//typo
.button {
  text-align: center;
}
#modalpopup {
    position:fixed;
    inset:0;
    z-index: 1000;

    .modalbg {
        position: absolute;
        inset:0;
        background-color: rgba(0,0,0,.87);
    }
    .modalcontent {
        position:absolute;
        @include rem-calc-sl(top, 16, 0.5);
        @include rem-calc-sl(bottom, 16, 0.5);
        @include rem-calc-sl(left, 64, 0.125);
        @include rem-calc-sl(right, 64, 0.8);
        background-color: #fff;
        color:#000;

        //Zentrieren, max 800px
        max-width:800px;
        margin-left: auto;
        margin-right: auto;

        iframe {
            @include absposfull;
            border:none;
        }
    }
    .closebutton {
        cursor:pointer;
        position:absolute;
        @include rem-calc-sl(top, 16, 0.5);
        @include rem-calc-sl(right, 16, 0.5);

        .icon {
            @include rem-calc-sl(height, 32, 1);
            color:$white;
        }
    }
}


.icon-sprites {
  display: none;
}

svg.icon {
  aspect-ratio: 1;
  height: 1em;
  //fill:currentColor;
}

svg.icon-scrolldown {
  aspect-ratio: 42 / 21;
}
svg.icon-totop {
  aspect-ratio: 42 / 21;
}
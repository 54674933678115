.bl-zitat {
    @include rem-calc-sl(padding-top, 200, 0.5);
    @include rem-calc-sl(padding-bottom, 200, 0.5);
    .zitat {
        max-width: rem-calc(590);
    }
    .author {
        margin-top:1em;
        border-top:2px solid currentColor;
        padding-top:1em;
        width:fit-content;
    }
}

//typo
.bl-zitat {
    .zitat {
        @include typo-zitat;
    }
}
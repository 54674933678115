.bl-textblock {
    .ctrtext {
        @include rem-calc-sl(padding-top, 150, 0.5);
        @include rem-calc-sl(padding-bottom, 150, 0.5);

        text-align: center;
    }
    .ctrbuttons {
        margin-top:3em;
    }
}

.bl-textblock {
    @include bgcolors;
}

//decorations
.bl-textblock {
    & > .linner {
        //Bring content in front of decoration
        position: relative;
    }
    
    .decoration-mandala {
        position: absolute;
        width:25vw;
        max-width:480px;
        right:0;
        transform: translateY(-50%);
        img {
            @include w100bl;
            height:100%;
        }
    }
}

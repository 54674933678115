.bl-textwithimage {
    .colimage {
        position: relative;
        .minhsizer {
            aspect-ratio: 1;
        }
        img {
            @include absposfull;
            object-fit: cover;
            object-position: center center;
        }
    }

    @include breakpoint(large) {
        .cols {
            @include grid;
            grid-template-columns: repeat(2, 1fr);
        }

        .coltext {
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        .text {
            width: 90%;
            max-width:rem-calc(520);
            padding-top:2em;
            padding-bottom:2em;
        }

        &.imgposr {
            .coltext {
                order: 1;
            }
            .colimage {
                order: 2;
            }
        }
        &.imgposl {
            .colimage {
                order: 1;
            }
            .coltext {
                order: 2;
            }
        }
    }
    @include breakpoint(medium down) {
        .coltext {
            margin-top:var(--layoutgridgap);

            padding-top:2em;
            padding-bottom:2em;
            padding-left: var(--lrminmargin);
            padding-right: var(--lrminmargin);
        }
        .ctrbuttons {
            margin-top:2em;
        }
    }
}

.bl-textwithimage {
    .coltext {
        @include bgcolors;
    }
}

//typo
.bl-textwithimage {
    .text {
        text-align: center;
    }
    .text a {
        @include text-decoration-underline;
    }
}
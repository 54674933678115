//Standardabstand zwischen zwei Bloecken
.cblock + .cblock {
    //@include rem-calc-sl(margin-top, 60, 0.5);
    margin-top: var(--layoutgridgap);
}

//Spezifische Abstaende
/*
.bl-title + .bl-text2col {
    margin-top:1em;
}
.bl-richtext + .bl-referenzspecs {
    @include rem-calc-sl(margin-top, 80, 0.5);
}

:is(.bl-imageblock, .bl-textwithimage) + :is(.bl-imageblock, .bl-textwithimage) {
    @include rem-calc-sl(margin-top, 70, 0.5);
}

.bl-title + .bl-richtext {
    //use margin of <h..> element
    margin-top:0;
}

.bl-richtext.lead1 + .cblock {
    margin-top:5em;
}

.cblock + .bl-quote,
.bl-richtext.lead1 + .bl-quote {
    margin-top: 8em;
}

.bl-sitetitle + .cblock {
    margin-top: 2.75em;
}

.bl-textwithimage + .bl-textwithimage {
    margin-top:5em;
}
*/


/*
.cblock + .bl-team {
    //@include rem-calc-sl(margin-top, 120, 0.5);
    margin-top: 5em;
}
*/
/*
.bl-title + .cblock {
    margin-top:2em;
}
*/

/*
.bl-sitetitle + .bl-textwithimage {
    @include rem-calc-sl(margin-top, 150, math.div(60, 150));
}

.bl-images2, .bl-images3 {
    margin-top:var(--imagesgap);
}
*/

/*
.ws1 {
    .bl-richtext + .bl-slider {
        margin-top:2em;
    }
    .bl-slider + .bl-linkboxes {
        margin-top:2em;
    }
}
*/

/*
.cblock + .bl-button {
    margin-top: 8em;
    margin-bottom: 8em;
}

.bl-textwithimage + .bl-textwithimage {
    margin-top:20em;
    @include breakpoint(medium down) {
        margin-top:12em;
    }
}
*/
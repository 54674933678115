#totopbutton {
    position:fixed;
    right:rem-calc(32);
    bottom:rem-calc(32);

    cursor: pointer;

    color: #b4b4b4; //entspricht currentColor im SVG
    .icon {
        height:rem-calc(50);
    }
}

/*
#totopbutton {
    opacity: 0;
    transition: opacity 0.2s;
    &.visible {
        opacity: 1;
    }
}
*/

#totopbutton {
    transition: all 0.2s;
    transform:translateX(200%);
    &.visible {
        transform: none;
    }
}

#totopbutton:hover {
    color: #64412C; //entspricht currentColor im SVG
}
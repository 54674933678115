body {
  overflow-x: clip;

  margin:0;
  padding:0;
}

body.topmargin1 {
  @include rem-calc-sl(margin-top, 100, 0.75);
}

section {
    @include rem-calc-sl(--baseunit, math.div(120,3), 0.5);
    @for $i from -5 through 10 {
        &.pt_#{$i} {
            padding-top:calc(#{$i} * var(--baseunit));
        }
        &.pb_#{$i} {
            padding-bottom:calc(#{$i} * var(--baseunit));
        }

        &.mt_#{$i} {
            margin-top:calc(#{$i} * var(--baseunit));
        }
        &.mb_#{$i} {
            margin-bottom:calc(#{$i} * var(--baseunit));
        }
    }
}
section:first-child {
    padding-top:0;
}

section {
    /*
    &.bgwhite {
        background-color: $white;
    }
    &.bgbrown {
        background-color: $brown;
    }
    &.bggreen {
        background-color: $green;
    }
    */
}

section.hasbgimage1 {
    @include breakpoint(large) {
        position: relative;
        .ctrbgimage {
            position: absolute;
            top:0;
            right: 0;
            bottom: 0;
            width: 43vw;
            clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);

            img {
                @include absposfull;
                object-fit: cover;
                object-position: center center;
            }
        }
        .ctrsectioncontent {
            width:55%;
        }
    }
    @include breakpoint(medium down) {
        .ctrbgimage {
            display: none;
        }
    }
}

section.colorsnegative1 {
    color:$black;
}